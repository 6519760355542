import {AnnotationObj} from '../models/apps/Annotation';

export const CREATE_NEW_ANNOTATION = 'CREATE_NEW_ANNOTATION';
export const DELETE_ANNOTATION = 'DELETE_ANNOTATION';
export const GET_ANNOTATION_LIST = 'GET_ANNOTATION_LIST';
export const GET_ANNOTATION_ITEM = 'GET_ANNOTATION_ITEM';
export const UPDATE_ANNOTATION_DETAIL = 'UPDATE_ANNOTATION_DETAIL';
export const TOGGLE_ANNOTATION_DRAWER = 'TOGGLE_ANNOTATION_DRAWER';
export const GET_ANNOTATION_EDITOR_ANNOTAION_ID = 'GET_ANNOTATION_EDITOR_ANNOTAION_ID'
export const GET_ANNOTATION_TAG = 'GET_ANNOTATION_TAG'
export const GET_OBSERVATIONS_TOFFLER_SPHERE = 'GET_OBSERVATIONS_TOFFLER_SPHERE'
export const GET_CONTENT_ANNOTATIONS = 'GET_CONTENT_ANNOTATIONS'


export interface CreateAnnotationActions {
    type: typeof CREATE_NEW_ANNOTATION;
    payload: AnnotationObj;
}

export interface DeleteAnnotationActions {
    type: typeof DELETE_ANNOTATION;
}

export interface GetAnnotationsActions {
  type: typeof GET_ANNOTATION_LIST;
  payload: {list: AnnotationObj[]; total: number};
}

export interface GetAnnotationItemActions {
  type: typeof GET_ANNOTATION_ITEM;
  payload: {item: AnnotationObj};
}

export interface UpdateAnnotationActions {
  type: typeof UPDATE_ANNOTATION_DETAIL;
  payload: AnnotationObj;
}

export interface ToggleAnnotationDrawerActions {
  type: typeof TOGGLE_ANNOTATION_DRAWER;
}

export interface GetAnnotationEditorAnnotationIdActions {
  type: typeof GET_ANNOTATION_EDITOR_ANNOTAION_ID;
  payload: {annotation: AnnotationObj; sphereTag: number; forceTag: number;};
}

export interface GetAnnotationTagActions {
  type: typeof GET_ANNOTATION_TAG;
  payload: {item: any};
}

export interface GetObservationsByTofflerSphere {
  type: typeof GET_OBSERVATIONS_TOFFLER_SPHERE;
  payload: {item: any};
}

export interface GetContentAnnotations {
  type: typeof GET_CONTENT_ANNOTATIONS;
  payload: {item: any};
}

export type AnnotationActions =
  | GetAnnotationsActions
  | UpdateAnnotationActions
  | CreateAnnotationActions
  | ToggleAnnotationDrawerActions
  | DeleteAnnotationActions
  | GetAnnotationItemActions
  | GetAnnotationEditorAnnotationIdActions
  | GetAnnotationTagActions
  | GetObservationsByTofflerSphere
  | GetContentAnnotations;
