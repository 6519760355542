import Settings from "./Setting";
import Common from "./Common";
import Auth from './Auth';
import Annotation from './Annotation';
import Content from './Content';
import Tag from './Tag';
import ContentType from './ContentType';
import Project from './Project';
import User from "./User";
import Forum  from "./Forum";

const reducers = {
  settings: Settings,
  common: Common,
  auth: Auth,
  annotation: Annotation, 
  content: Content,
  tag: Tag,
  contentType: ContentType,
  project: Project,
  user: User,
  forum: Forum,
};

export default reducers;
