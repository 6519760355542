import {ProjectObj} from '../models/apps/Project';

export const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const GET_PROJECT_LIST = 'GET_PROJECT_LIST';
export const GET_PROJECT_ITEM = 'GET_PROJECT_ITEM';
export const UPDATE_PROJECT_DETAIL = 'UPDATE_PROJECT_DETAIL';
export const TOGGLE_PROJECT_DRAWER = 'TOGGLE_PROJECT_DRAWER';

export interface CreateProjectActions {
    type: typeof CREATE_NEW_PROJECT;
    payload: ProjectObj;
}

export interface DeleteProjectActions {
    type: typeof DELETE_PROJECT;
    payload: {project: ProjectObj[]; totalElements: number};
}

export interface GetProjectsActions {
  type: typeof GET_PROJECT_LIST;
  payload: {project: ProjectObj[]; totalElements: number};
}

export interface GetProjectItemActions {
  type: typeof GET_PROJECT_ITEM;
  payload: {item: ProjectObj};
}

export interface UpdateProjectActions {
  type: typeof UPDATE_PROJECT_DETAIL;
  payload: ProjectObj;
}

export interface ToggleProjectDrawerActions {
  type: typeof TOGGLE_PROJECT_DRAWER;
}

export type ProjectActions =
  | GetProjectsActions
  | UpdateProjectActions
  | CreateProjectActions
  | ToggleProjectDrawerActions
  | DeleteProjectActions
  | GetProjectItemActions;
