import {
    ContentTypeActions,
    GET_CONTENTTYPE_LIST,
    CREATE_NEW_CONTENTTYPE,
    GET_CONTENTTYPE_ITEM,
    DELETE_CONTENTTYPE
} from '../../types/actions/ContentType.action';

import {ContentTypeObj} from '../../types/models/apps/ContentType';


const initialState: {
 contentTypeList: ContentTypeObj[];
 totalContentTypes: number;
 selectedContentType: ContentTypeObj[] | null;
 contentTypeItem: ContentTypeObj;
} = {
 contentTypeList: [],
 totalContentTypes: 0,
 selectedContentType: null,
 contentTypeItem: {
    id: 0,
    name: '',
    domain: 'DEMOOO',
    status: 'DRAFT',
    createdBy: '',
    createdDate: new Date(),
    lastModifiedBy: '',
    lastModifiedDate: new Date(),
 },
};

const ContentTypeReducer = (state= initialState, action: ContentTypeActions) => {
    switch (action.type) {
        case GET_CONTENTTYPE_LIST:
            return{
                ...state,
                contentTypeList: action.payload,
                totalContentTypes: action.payload.totalElements
        } 
        case GET_CONTENTTYPE_ITEM:
            return{
                ...state,
                contentTypeItem: action.payload,
                loading: true
        } 
        case CREATE_NEW_CONTENTTYPE:
            return {
            ...state,
            contentTypeItem:action.payload,
        };       
        case DELETE_CONTENTTYPE:
            return {
            ...state,
            contentTypeList: action.payload,
            totalContentTypes: action.payload.totalElements
        };
        default:
            return state;
    }

} 

export default ContentTypeReducer;