import { number } from 'prop-types';
import { ContentTypeObj } from 'types/models/apps/ContentType';
import { ProjectObj } from 'types/models/apps/Project';
import {
    ContentActions,
    GET_CONTENT_LIST,
    CREATE_NEW_CONTENT,
    GET_CONTENT_ITEM,
    DELETE_CONTENT,
    UPDATE_CONTENT_DETAIL,
    PROCESSING_FILE_UPLOAD,
    PROGRES_FILE_UPLOAD,
    FAILED_FILE_UPLOAD,
    GET_ANNOUNCEMENT_LIST,
    GET_FEATURED_CONTENT_LIST,
    CLOSING_FILE_UPLOAD,
    GET_CONTENT_FILTER_LIST,
    CONTENT_TAB_VALUE,
    CREATE_NEW_QUESTION,
    GET_ANSWER_LIST
} from '../../types/actions/Content.action';

import {ContentObj} from '../../types/models/apps/Content';

const contentType: ContentTypeObj= {
    id: 0,
    name: '',
    domain: '',
    status: '',
    createdBy: '',
    createdDate: new Date(),
    lastModifiedBy: '',
    lastModifiedDate: new Date()
};

const project: ProjectObj= {
    id: 0,
    name: '',
    domain: '',
    status: '',
    createdBy: '',
    createdDate: new Date(),
    lastModifiedBy: '',
    lastModifiedDate: new Date()
   };
const initialState: {
 contentList: ContentObj[];
 contentFilterList: ContentObj[];
 featuredContentList: ContentObj[];
 totalContents: number;
 selectedContent: ContentObj[] | null;
 contentItem: ContentObj;
 closeUpload: boolean;
} = {
 contentList: [],
 contentFilterList: [],
 featuredContentList: [],
 totalContents: 0,
 selectedContent: null,
 contentItem: {
    id: 0,
    contentType: contentType,
    name: '',
    contentHtml: '',
    url: '',
    author: '',
    description: '',
    client: '',
    industry: '',
    serviceOffering: '',
    source: '',
    publishingDate: new Date(),
    project: project,
    domain: '',
    status: '',
    createdBy: '',
    createdDate: new Date(),
    lastModifiedBy: '',
    lastModifiedDate: new Date(),
    contentClass: '',
    postStatus: '',
    previewImageSmall: undefined,
    postStartDateTime: new Date,
    postEndDateTime: new Date,
    poststartTimezone: '',
    postendTimezone: '',
    actionUrl: '',
    isFeatured: false,
    parentcontent: null
 },
 closeUpload: true,
};


const ContentReducer = (state= initialState, action: ContentActions) => {
    switch (action.type) {
        case GET_CONTENT_LIST:
            return{
                ...state,
                contentList: action.payload.content,
                totalContents: action.payload.totalElements,
        } 
        case GET_CONTENT_FILTER_LIST:
            return{
                ...state,
                contentFilterList: action.payload.content,
                totalFilterContents: action.payload.totalElements,
        }  
        case GET_ANNOUNCEMENT_LIST:
            return{
                ...state,
                announcementList: action.payload.content,
                totalAnnouncements: action.payload.totalElements,
        } 
        case GET_FEATURED_CONTENT_LIST:
            return{
                ...state,
                featuredContentList: action.payload.content,
                totalFeaturedContents: action.payload.totalElements,
        }  
        case GET_ANSWER_LIST:
            return{
                ...state,
                childList: action.payload,
        } 
        case  PROCESSING_FILE_UPLOAD:
            return{
                ...state,
                processing: action.payload,
        }
        case  FAILED_FILE_UPLOAD:
            return{
                ...state,
                failedFiles: action.payload,
        } 
        case  CLOSING_FILE_UPLOAD:
            return{
                ...state,
                closeUpload: action.payload,
        } 
        case  PROGRES_FILE_UPLOAD:
            return{
                ...state,
                progress: action.payload,
        }
        
        case GET_CONTENT_ITEM:
            return{
                ...state,
                contentItem: action.payload,
        } 
        case CREATE_NEW_CONTENT:
            return {
            ...state,
            contentItem:action.payload,
        };  
        case UPDATE_CONTENT_DETAIL:
            return{
                ...state,
                contentItem: action.payload,
        }      
        case DELETE_CONTENT:
            return {
            ...state,
            contentList: action.payload.content,
            totalContents: action.payload.totalElements
        };
        case CONTENT_TAB_VALUE:
            return {
            ...state,
            contentTabValue: action.payload
        };
        case CREATE_NEW_QUESTION:
            return {
            ...state,
            questionItem: action.payload
            }    
        default:
            return state;
    }

} 

export default ContentReducer;