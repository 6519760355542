import {ContentTypeObj} from '../models/apps/ContentType';

export const CREATE_NEW_CONTENTTYPE = 'CREATE_NEW_CONTENTTYPE';
export const DELETE_CONTENTTYPE = 'DELETE_CONTENTTYPE';
export const GET_CONTENTTYPE_LIST = 'GET_CONTENTTYPE_LIST';
export const GET_CONTENTTYPE_ITEM = 'GET_CONTENTTYPE_ITEM';
export const UPDATE_CONTENTTYPE_DETAIL = 'UPDATE_CONTENTTYPE_DETAIL';
export const TOGGLE_CONTENTTYPE_DRAWER = 'TOGGLE_CONTENTTYPE_DRAWER';

export interface CreateContentTypeActions {
    type: typeof CREATE_NEW_CONTENTTYPE;
    payload: ContentTypeObj;
}

export interface DeleteContentTypeActions {
    type: typeof DELETE_CONTENTTYPE;
    payload: {contentType: ContentTypeObj[]; totalElements: number};
}

export interface GetContentTypesActions {
  type: typeof GET_CONTENTTYPE_LIST;
  payload: {contentType: ContentTypeObj[]; totalElements: number};
}

export interface GetContentTypeItemActions {
  type: typeof GET_CONTENTTYPE_ITEM;
  payload: {item: ContentTypeObj};
}

export interface UpdateContentTypeActions {
  type: typeof UPDATE_CONTENTTYPE_DETAIL;
  payload: ContentTypeObj;
}

export interface ToggleContentTypeDrawerActions {
  type: typeof TOGGLE_CONTENTTYPE_DRAWER;
}

export type ContentTypeActions =
  | GetContentTypesActions
  | UpdateContentTypeActions
  | CreateContentTypeActions
  | ToggleContentTypeDrawerActions
  | DeleteContentTypeActions
  | GetContentTypeItemActions;
