import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import { Checkbox, colors, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { grey } from "@mui/material/colors";
import { color } from "@mui/system";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Fonts } from "shared/constants/AppEnums";
import moment from 'moment';
import PersonIcon from '@mui/icons-material/Person';


const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface ListItemProps {
  item: any;
}

const CommentListItem: React.FC<ListItemProps> = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        p: 5,
        mb: 4,
        cursor: "pointer",
      }}
      className="item-hover"
      onClick={() => {
       // dispatch(setCurrentProduct(item));
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "calc(100% - 8rem)",
              xl: "calc(100% - 10rem)",
            },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
              pb: 6,
              mt: -4,
            }}
          >
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
              mt: -4,
              width: '900',
            }}
          >
            <Box
              component="h3"
              sx={{
                color: "text.secondary",
                mb: 5,
                fontSize: 14,
                width: 1200
              }}
            >
              <Typography variant="h1" dangerouslySetInnerHTML={{ __html: item.contentHtml }} ></Typography> 
            </Box>
          </Box>
          <Box
            sx={{
              mt: "auto",
              mb: -3,
              mx: { xs: -2, xl: -3 },
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                px: { xs: 2, xl: 3 },
                mb: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mr: 1,
                }}
              >
              </Box>
              <Box
                component="span"
                sx={{
                  fontSize: 14,
                }}
              >
               
                <Typography>
                <PersonIcon/>  commented {  moment(item.createdDate).format("MMMM Do YYYY, H:MM ") } by {item.createdBy}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CommentListItem;
