import { AppCard, AppList } from "@crema";
import ListEmptyResult from "@crema/core/AppList/ListEmptyResult";
import { useAuthUser } from "@crema/utility/AuthHooks";
import IntlMessages from "@crema/utility/IntlMessages";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { onCreateAnswer, onGetChildren, onGetContentItem } from "redux/actions/Content";
import { AppState } from "redux/store";
import { CremaTheme } from "types/AppContextPropsType";
import { ContentClass } from "types/constants/ContentClass";
import { ContentObj } from "types/models/apps/Content";
import AnswerListItem from "./AnswerListItem";
import CommentListItem from "./CommentListItem";
import PersonIcon from '@mui/icons-material/Person';
import moment from "moment";

const ReviewQuestion = () => {

  const tinyMCE_key = (process.env.REACT_APP_TINYMCE_KEY as string);

  const [loading, setLoading] = useState<boolean>(false);

  const useStyles = makeStyles((theme: CremaTheme) => ({

  }));

  const { id } = useParams();
  const { forumName } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { messages } = useIntl();
  const editorRef = useRef(null);
  const { addToast } = useToasts();

  const { user } = useAuthUser();

  const classes = useStyles();

  useEffect(() => {
    dispatch(onGetContentItem(id));
    dispatch(onGetChildren(10, 0, user ? user.domain : '', id));
  }, []);

  const {
    contentItem,
  }: { contentItem: ContentObj } = useSelector<
    AppState,
    AppState['content']
  >(({ content }) => content);

  const {
    childList,
    totalAnswers
  }: { childList: ContentObj[], totalAnswers: number } = useSelector<
    AppState,
    AppState['content']
  >(({ content }) => content);

  const onQuestionHomePage = () => {
    navigate("/forums/" + forumName );
  }

  const changeContentHtml = (event) => {
    setContentHtml(event);
  }

  const [contentHtml, setContentHtml] = useState<string>('');

  const [showAnswer, onShowAnswer] = useState<string>('none');

  const [showComment, onShowComment] = useState<string>('none');

  const newAnswer: any = {
    id: 0,
    name: contentItem.name,
    contentHtml: contentHtml,
    lastModifiedDate: new Date(),
    domain: user ? user.domain : '',
    contentClass: ContentClass.ANSWER,
    parentcontent: contentItem
  };

  const newComment: any = {
    id: 0,
    name: contentItem.name,
    contentHtml: contentHtml,
    lastModifiedDate: new Date(),
    domain: user ? user.domain : '',
    contentClass: ContentClass.COMMENT,
    parentcontent: contentItem
  };

  const saveAnswer = () => {
    console.log(newAnswer)
    dispatch(onCreateAnswer(newAnswer, contentItem.id , addToast));
    onShowAnswer('none');
    setContentHtml('');
  }

  const saveComment = () => {
    console.log(newComment)
    dispatch(onCreateAnswer(newComment, contentItem.id , addToast));
    onShowComment('none');
    setContentHtml('');
  }

  return (
    <>
      <AppCard sx={{ p: 10 }}>
        <Grid container sx={{ pb: 2 }} >
          <Grid item xs={10}>
            <Typography variant="h1">{contentItem.name}</Typography>
          </Grid>
          <Grid xs={2}>
            <Button
              color='secondary'
              size="large"
              variant="contained"
              onClick={onQuestionHomePage}>
              <IntlMessages id='common.cancel' />
            </Button>
          </Grid>
        </Grid>
        <hr />

        <Grid container sx={{ pt: 2 }} >
          <Grid item xs={8} >

            <Grid container sx={{ pt: 2 }} >
              <Grid item xs={12} >
                <Typography variant="h1" dangerouslySetInnerHTML={{ __html: contentItem.contentHtml }} ></Typography>
                <Box
                  component="span"
                  sx={{
                    fontSize: 6,
                    p: 5,
                  }}
                >
                  <Typography>
                    <PersonIcon />  asked {moment(contentItem.createdDate).format("MMMM Do YYYY, H:MM ")} by {contentItem.createdBy}
                  </Typography>
                </Box>  
                <Grid container sx={{ pt: 2 }} >
                  <Grid item xs={12} >
                    <AppList
                      delay={200}
                      data={childList?.filter(item => (item.contentClass === ContentClass.COMMENT && item.parentcontent?.id === Number(id)))}
                      renderRow={(item) => <CommentListItem item={item} key={item.id} />}
                    />
                  </Grid>
                </Grid>
                <Box display={showComment}>
                  <Grid container sx={{ pt: 4 }}>
                    <Grid item xs={8}>
                      <Typography>Your comment</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack spacing={4} direction="row">
                        <Button
                          color='primary'
                          size="large"
                          variant="contained"
                          onClick={saveComment}
                        >
                          <IntlMessages id='forum.addComment' />
                        </Button>
                        <Button
                          color='secondary'
                          size="large"
                          variant="contained"
                          onClick={() => onShowComment('none')}
                        >
                          <IntlMessages id='common.cancel' />
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ pt: 4 }}>
                    <Grid item xs={12}>
                      <Editor
                        apiKey={tinyMCE_key}
                        onInit={(evt: any, editor: any) => editorRef.current = editor}
                        value={contentHtml}
                        // initialValue={newQuestion.contentHtml}
                        onEditorChange={(newContentValue, editor) => { changeContentHtml(newContentValue) }}
                        init={{
                          height: 150,
                          menubar: false,
                          toolbar_mode: 'scrolling',
                          toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Stack spacing={4} direction="row" sx={{ pb: 4, pt: 4 }}>
                  <Button
                    color='success'
                    size="large"
                    variant="contained"
                    onClick={() => onShowAnswer('inline')}
                  >
                    <IntlMessages id='forum.answer' />
                  </Button>
                  <Button

                    size="large"
                    variant="outlined"
                    onClick={() => onShowComment('inline')}
                  >
                    <IntlMessages id='forum.comment' />
                  </Button>
                </Stack>
                <hr />
              </Grid>
            </Grid>
            <Box display={showAnswer}>
              <Grid container sx={{ pt: 4 }}>
                <Grid item xs={8}>
                  <Typography>Your answer</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Stack spacing={4} direction="row">
                    <Button
                      color='primary'
                      size="large"
                      variant="contained"
                      onClick={saveAnswer}
                    >
                      <IntlMessages id='forum.addAnswer' />
                    </Button>
                    <Button
                      color='secondary'
                      size="large"
                      variant="contained"
                      onClick={() => onShowAnswer('none')}
                    >
                      <IntlMessages id='common.cancel' />
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container sx={{ pt: 4 }}>
                <Grid item xs={12}>
                  <Editor
                    apiKey={tinyMCE_key}
                    onInit={(evt: any, editor: any) => editorRef.current = editor}
                    value={contentHtml}
                    // initialValue={newQuestion.contentHtml}
                    onEditorChange={(newContentValue, editor) => { changeContentHtml(newContentValue) }}
                    init={{
                      height: 250,
                      menubar: false,
                      toolbar_mode: 'scrolling',
                      toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container sx={{ pt: 2 }} >
              <Grid item xs={12} >
                <AppList
                  delay={200}
                  data={childList?.filter(item => (item.contentClass === ContentClass.ANSWER && item.parentcontent?.id === Number(id)))}
                  renderRow={(item) => <AnswerListItem item={item} key={item.id} />}
                  ListEmptyComponent={
                    <ListEmptyResult content="No Answers" loading={loading} />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={4}>
          </Grid>
        </Grid>

      </AppCard>
    </>
  )
}

export default ReviewQuestion;