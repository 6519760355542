import axios from 'axios';
const configValue: string = (process.env.REACT_APP_BASE_URL as string);

const AUTHE_PRIFEX:string = "Bearer ";

const jwtAxios = axios.create({ 
  baseURL: configValue, 
  headers: {
    'Content-Type': 'application/json',
  },
});

jwtAxios.interceptors.request.use(
  (res) => {

    res.headers.common.Authorization = `Bearer ${localStorage.getItem("token")}`;
    
    return Promise.resolve(res);
  },
  (err) => {
    if (err.response && err.response.data.msg === "Token is not valid") {
      console.log("Need to logout user");
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  }
);

export const setAuthToken = (token: string | null) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = AUTHE_PRIFEX + token;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
