import React, { useEffect } from "react";
import List from "@mui/material/List";

import routesConfig, {
  RouterConfigData,
} from "../../../../../pages/routesConfig";
import NavVerticalGroup from "./VerticalNavGroup";
import VerticalCollapse from "./VerticalCollapse";
import VerticalItem from "./VerticalItem";

import { useDispatch, useSelector } from "react-redux";
import { onGetForumList } from "redux/actions/Forum";
import { AppState } from "redux/store";
import { ForumObj } from "types/models/apps/Forum";

const VerticalNav = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onGetForumList());
  }, []);

  const {
    forumList,
  }: { forumList: ForumObj[] } = useSelector<
    AppState,
    AppState['forum']
  >(({ forum }) => forum);
  
  let children: RouterConfigData[] =  []
  
  forumList.forEach(element => {

    const routeObject: any = {
      id: element.name,
      title: element.name,
      messageId: element.name,
      type: 'item',
      icon: element.name,
      url: 'forums/' + element.name ,
    }
    children = [...children, routeObject]
  });

  const forumRoutesConfig : RouterConfigData[] = [{
    id: 'forums',
    title: 'forums',
    messageId: 'sidebar.forums',
    type: 'collapse',
    icon: 'dashboard',
    children: children
  }]

  
  return (
    <List
      sx={{
        position: "relative",
        padding: 0,
      }}
      component="div"
    >
      {routesConfig.map((item: RouterConfigData) => (
        <React.Fragment key={item.id}>
          {item.type === "group" && <NavVerticalGroup item={item} level={0} />}

          {item.type === "collapse" && (
            <VerticalCollapse item={item} level={0} />
          )}

          {item.type === "item" && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
      {forumRoutesConfig.map((item: RouterConfigData) => (
        <React.Fragment key={item.id}>
          {item.type === "group" && <NavVerticalGroup item={item} level={0} />}

          {item.type === "collapse" && (
            <VerticalCollapse item={item} level={0} />
          )}

          {item.type === "item" && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default VerticalNav;
