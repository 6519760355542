import {
    UserActions,
    GET_USER_LIST,
    CREATE_NEW_USER,
    GET_USER_ITEM,
    DELETE_USER
} from '../../types/actions/User.action';

import {UserObj} from '../../types/models/apps/User';


const initialState: {
 userList: UserObj[];
 totalUsers: number;
 selectedUser: UserObj[] | null;
 userItem: UserObj;
} = {
 userList: [],
 totalUsers: 0,
 selectedUser: null,
 userItem: {
    id: 0,
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    enabled: '',
    authorities: [],
    domain: '',
    status: '',
    createdBy: '',
    createdDate: new Date(),
    lastModifiedBy: '',
    lastModifiedDate: new Date(),
 },
};

const UserReducer = (state= initialState, action: UserActions) => {
    switch (action.type) {
        case GET_USER_LIST:
            return{
                ...state,
                userList: action.payload.content,
                totalUsers: action.payload.totalElements
        } 
        case GET_USER_ITEM:
            return{
                ...state,
                userItem: action.payload,
                loading: true
        } 
        case CREATE_NEW_USER:
            return {
            ...state,
            userItem:action.payload,
        };       
        case DELETE_USER:
            return {
            ...state,
            userList: action.payload,
            totalUsers: action.payload.totalElements
        };
        default:
            return state;
    }

} 

export default UserReducer;