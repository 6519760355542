import {ForumObj} from '../models/apps/Forum';

export const CREATE_NEW_FORUM = 'CREATE_NEW_FORUM';
export const DELETE_FORUM = 'DELETE_FORUM';
export const GET_FORUM_LIST = 'GET_FORUM_LIST';
export const GET_FORUM_ITEM = 'GET_FORUM_ITEM';
export const GET_ANNOTATION_FORUM = 'GET_ANNOTATION_FORUM';
export const UPDATE_FORUM_DETAIL = 'UPDATE_FORUM_DETAIL';
export const TOGGLE_FORUM_DRAWER = 'TOGGLE_FORUM_DRAWER';
export const GET_FORUM_COUNT = 'GET_FORUM_COUNT'

export interface CreateForumActions {
    type: typeof CREATE_NEW_FORUM;
    payload: ForumObj;
}

export interface DeleteForumActions {
    type: typeof DELETE_FORUM;
    payload: {content: ForumObj[]; totalElements: number};
}

export interface GetForumsActions {
  type: typeof GET_FORUM_LIST;
  payload: {content: ForumObj[]; totalElements: number};
}

export interface GetForumItemActions {
  type: typeof GET_FORUM_ITEM;
  payload: {item: ForumObj};
}

export interface GetForumLEVELActions {
  type: typeof GET_ANNOTATION_FORUM;
  payload: {item: any};
}

export interface UpdateForumActions {
  type: typeof UPDATE_FORUM_DETAIL;
  payload: ForumObj;
}

export interface ToggleForumDrawerActions {
  type: typeof TOGGLE_FORUM_DRAWER;
}

export interface GetForumCount {
  type: typeof GET_FORUM_COUNT;
  payload: any;
}

export type ForumActions =
  | GetForumsActions
  | UpdateForumActions
  | CreateForumActions
  | ToggleForumDrawerActions
  | DeleteForumActions
  | GetForumItemActions
  | GetForumLEVELActions
  | GetForumCount;

