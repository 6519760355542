import {AnnotationTagObj} from '../models/apps/AnnotationTag';

export const CREATE_NEW_ANNOTATIONTAG = 'CREATE_NEW_ANNOTATIONTAG';
export const DELETE_ANNOTATIONTAG = 'DELETE_ANNOTATIONTAG';
export const GET_ANNOTATIONTAG_LIST = 'GET_ANNOTATIONTAG_LIST';
export const GET_ANNOTATIONTAG_ITEM = 'GET_ANNOTATIONTAG_ITEM';
export const GET_ANNOTATIONTAG_BY_LEVEL = 'GET_ANNOTATIONTAG_BY_LEVEL';
export const UPDATE_ANNOTATIONTAG_DETAIL = 'UPDATE_ANNOTATIONTAG_DETAIL';
export const TOGGLE_ANNOTATIONTAG_DRAWER = 'TOGGLE_ANNOTATIONTAG_DRAWER';

export interface CreateANNOTATIONTAGActions {
    type: typeof CREATE_NEW_ANNOTATIONTAG;
    payload: AnnotationTagObj;
}

export interface DeleteANNOTATIONTAGActions {
    type: typeof DELETE_ANNOTATIONTAG;
    payload: {content: AnnotationTagObj[]; totalElements: number};
}

export interface GetANNOTATIONTAGsActions {
  type: typeof GET_ANNOTATIONTAG_LIST;
  payload: {content: AnnotationTagObj[]; totalElements: number};
}

export interface GetANNOTATIONTAGItemActions {
  type: typeof GET_ANNOTATIONTAG_ITEM;
  payload: {item: AnnotationTagObj};
}

export interface GetANNOTATIONTAGLEVELActions {
  type: typeof GET_ANNOTATIONTAG_BY_LEVEL;
  payload: {item: AnnotationTagObj[]};
}

export interface UpdateANNOTATIONTAGActions {
  type: typeof UPDATE_ANNOTATIONTAG_DETAIL;
  payload: AnnotationTagObj;
}

export interface ToggleANNOTATIONTAGDrawerActions {
  type: typeof TOGGLE_ANNOTATIONTAG_DRAWER;
}

export type AnnotationTagActions =
  | GetANNOTATIONTAGsActions
  | UpdateANNOTATIONTAGActions
  | CreateANNOTATIONTAGActions
  | ToggleANNOTATIONTAGDrawerActions
  | DeleteANNOTATIONTAGActions
  | GetANNOTATIONTAGItemActions
  | GetANNOTATIONTAGLEVELActions;
