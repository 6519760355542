import {UserObj} from '../models/apps/User';

export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_USER_ITEM = 'GET_USER_ITEM';
export const UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL';
export const TOGGLE_USER_DRAWER = 'TOGGLE_USER_DRAWER';

export interface CreateUserActions {
    type: typeof CREATE_NEW_USER;
    payload: UserObj;
}

export interface DeleteUserActions {
    type: typeof DELETE_USER;
    payload: {user: UserObj[]; totalElements: number};
}

export interface GetUsersActions {
  type: typeof GET_USER_LIST;
  payload: {content: UserObj[]; totalElements: number};
}

export interface GetUserItemActions {
  type: typeof GET_USER_ITEM;
  payload: {item: UserObj};
}

export interface UpdateUserActions {
  type: typeof UPDATE_USER_DETAIL;
  payload: UserObj;
}

export interface ToggleUserDrawerActions {
  type: typeof TOGGLE_USER_DRAWER;
}

export type UserActions =
  | GetUsersActions
  | UpdateUserActions
  | CreateUserActions
  | ToggleUserDrawerActions
  | DeleteUserActions
  | GetUserItemActions;
