import axios from '../../shared/api-config';
import { appIntl } from "../../@crema/utility/helper/Utils";
import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {Dispatch} from 'redux';
import {AppActions} from '../../types';

import {
  CREATE_NEW_TAG,
    DELETE_TAG,
    GET_ANNOTATION_TAG,
    GET_TAG_COUNT,
    GET_TAG_ITEM,
    GET_TAG_LIST
  } from '../../types/actions/Tag.action';
import { TagObj } from 'types/models/apps/Tag';
import { onCreateAnnotationTag } from './AnnotationTag';
import { AnnotationTagObj } from 'types/models/apps/AnnotationTag';

import {  useDispatch } from 'react-redux';



export const onGetTagList = (
  domain: string
) => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/tags', {
      params: {
        domain: domain
      },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_TAG_LIST, payload: data.data});
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};


export const onGetTagItem = (id: number) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.get('api/tag/'+id, {
      })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_TAG_ITEM, payload: data.data});
          } else {
            dispatch(
              fetchError(messages['message.somthingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };

  export const onGetTagCount = (contentIds: Array<number>, domain: string) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.get('api/tag/count', {
          params: {
            domain: domain,
            contentIds: contentIds
          }
      })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_TAG_COUNT, payload: data.data});
          } else {
            dispatch(
              fetchError(messages['message.somthingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };

  export const onGetAnnotationTag = (id: number) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.get('api/tag/annotation/' + id, {
      })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_ANNOTATION_TAG, payload: data.data});
          } else {
            dispatch(
              fetchError(messages['message.somthingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };  

  export const onCreateTag = (Tag: TagObj,annotationTag: AnnotationTagObj,usedispatch: any, domain: string) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.post('api/tag', Tag)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: CREATE_NEW_TAG, payload: data.data});
            annotationTag.tag=data.data;
            usedispatch(onCreateAnnotationTag(annotationTag as AnnotationTagObj)); 
            usedispatch(onGetTagList(domain));
          }
          else {
            dispatch(
              fetchError(messages['message.somethingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };

  export const onDeleteTag = (id: number) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.post('api/tag/'+ id)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: DELETE_TAG, payload: data.data});
            dispatch(showMessage(messages['message.AnnotationCreated'] as string));
          } else {
            dispatch(
              fetchError(messages['message.somethingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  }; 
  