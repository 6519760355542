import { any } from 'prop-types';
import {
    AnnotationActions,
    GET_ANNOTATION_LIST,
    CREATE_NEW_ANNOTATION,
    GET_ANNOTATION_ITEM,
    GET_ANNOTATION_EDITOR_ANNOTAION_ID,
    DELETE_ANNOTATION,
    GET_ANNOTATION_TAG,
    GET_OBSERVATIONS_TOFFLER_SPHERE,
    GET_CONTENT_ANNOTATIONS
} from '../../types/actions/Annotation.action';

import {AnnotationObj} from '../../types/models/apps/Annotation';

const initialState: {
 AnnotationList: AnnotationObj[];
 totalAnnotations: number;
 annotationObj: AnnotationObj;
 observationsTofflerSphere: any;
 contentAnnotations: any;
} = {
 AnnotationList: [],
 totalAnnotations: 0,
 annotationObj: {
    id: 0,
    editorAnnotationId: '',
    content: null,
    annotationHtml: '',
    annotationCommentHtml: '',
    tags: [],
    tagsString: '',
    area: '',
    subArea: '',
    projectSphere: '',
    projectForce: '',
    dataType: '',
    dateOfRelevance: new Date(),
    bestUsedBy: new Date(),
    geographicDetails: '',
    domain: '',
    status: '',
    createdBy: '',
    createdDate: new Date(),
    lastModifiedBy: '',
    lastModifiedDate: new Date()
 },
 observationsTofflerSphere: [],
 contentAnnotations: [],
};

const AnnotationReducer = (state= initialState, action: AnnotationActions) => {
    switch (action.type) {
        case GET_ANNOTATION_LIST:
            return{
                ...state,
                AnnotationList: action.payload,
                totalAnnotations: action.payload.total
        } 
        case GET_ANNOTATION_ITEM:
            return{
                ...state,
                AnnotationItem: action.payload,
                loading: true
        } 
        case CREATE_NEW_ANNOTATION:
            return {
            ...state,
            annotationObj:action.payload,
            annotationForceTag:null,
            annotationSphereTag:null,

        }
        case GET_ANNOTATION_EDITOR_ANNOTAION_ID:
            return {
            ...state,
           annotationObj:action.payload.annotation,
           annotationForceTag:action.payload.forceTag,
           annotationSphereTag:action.payload.sphereTag,
        };
        case GET_ANNOTATION_TAG:
            return {
            ...state,
          //  AnnotationList: [action.payload, ...state.AnnotationList],
           // totalAnnotations: state.totalAnnotations + 1,
            annotationTags:action.payload,
        };

        case GET_CONTENT_ANNOTATIONS:
            return {
            ...state,
            contentAnnotations:action.payload,
        };

        case DELETE_ANNOTATION:
            return {
            ...state,
        }; 
        case GET_OBSERVATIONS_TOFFLER_SPHERE:
            return {
            ...state,
           observationsTofflerSphere:action.payload,
        };      
        default:
            return state;
    }
    
} 

export default AnnotationReducer;