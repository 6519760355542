import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {Dispatch} from 'redux';
import { appIntl } from "../../@crema/utility/helper/Utils";
import {AppActions} from '../../types';
import axios from '../../shared/api-config';

import {
  CLOSING_FILE_UPLOAD,
  CREATE_NEW_CONTENT,
    CREATE_NEW_QUESTION,
    DELETE_CONTENT,
    FAILED_FILE_UPLOAD,
    GET_ANNOUNCEMENT_LIST,
    GET_ANSWER_LIST,
    GET_CONTENT_FILTER_LIST,
    GET_CONTENT_ITEM,
    GET_CONTENT_LIST,
    GET_FEATURED_CONTENT_LIST,
    PROCESSING_FILE_UPLOAD,
    PROGRES_FILE_UPLOAD
  } from '../../types/actions/Content.action';
import { ContentObj } from 'types/models/apps/Content';
import { onGetTagCount } from './Tag';
import { ContentClass } from 'types/constants/ContentClass';
import { onGetAnnotationsByContentIds } from './Annotation';

export const onGetContentList = (
  pageSize: number,
  currentPage: number,
  filterText: string,
  domain: string,
  contentClass: string
) => {
  const {messages} = appIntl();
  const page = currentPage ? currentPage : 0;
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/contents/search/', {
      params: {
        pageSize: pageSize,
        page: page,
        filterText: filterText,
        domain: domain,
        contentClass: contentClass
      },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_CONTENT_LIST, payload: data.data});
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetQuestionList = (
  pageSize: number,
  currentPage: number,
  filterText: string,
  domain: string,
  contentClass: string,
  forumName?: string
) => {
  const {messages} = appIntl();
  const page = currentPage ? currentPage : 0;
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/contents/forums', {
      params: {
        pageSize: pageSize,
        page: page,
        filterText: filterText,
        domain: domain,
        contentClass: contentClass,
        forumName: forumName
      },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_CONTENT_LIST, payload: data.data});
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetContentFilter = (
  pageSize: number,
  currentPage: number,
  filterText: string,
  domain: string,
  sphereTagIds: Array<number>,
  forceTagIds: Array<number>,
  tagTagIds: Array<number>,
  fromDate: any,
  toDate: any,
  useDispatch: any
) => {
  const {messages} = appIntl();
  const page = currentPage ? currentPage : 0;
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/contents/filter/', {
      params: {
        pageSize: pageSize,
        page: page,
        filterText: filterText,
        domain: domain,
        sphereTagId: sphereTagIds,
        forceTagId: forceTagIds,
        tagTagId: tagTagIds,
        fromDate:fromDate,
        toDate:toDate
      },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_CONTENT_FILTER_LIST, payload: data.data});
          let contentIds: Array<number> = []
          data.data.content.map((c: any) => {
            contentIds = [...contentIds, c.id]
          })
          useDispatch(onGetTagCount(contentIds, domain));
          useDispatch(onGetAnnotationsByContentIds(contentIds));
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};


export const onGetContentItem = (id?: string) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.get('api/content/'+id, {
      })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_CONTENT_ITEM, payload: data.data});
          } else {
            dispatch(
              fetchError(messages['message.somthingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };


  export const onGetAnnouncement = (
    pageSize: number,
    currentPage: number,
    domain: string,
    contentClass: string,
  ) => {
    const {messages} = appIntl();
    const page = currentPage ? currentPage : 0;
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.get('api/contents/announcement/', {
        params: {
          pageSize: pageSize,
          page: page,
          domain: domain,
          contentClass: contentClass,
        },
      })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_ANNOUNCEMENT_LIST, payload: data.data});
          } else {
            dispatch(
              fetchError(messages['message.somthingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };

  export const onGetFeaturedContents = (
    pageSize: number,
    currentPage: number,
    filterText: string,
    domain: string,
    contentClass: string,
  ) => {
    const {messages} = appIntl();
    const page = currentPage ? currentPage : 0;
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.get('api/contents/featured/', {
        params: {
          pageSize: pageSize,
          page: page,
          filterText: filterText,
          domain: domain,
          contentClass: contentClass,
        },
      })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_FEATURED_CONTENT_LIST, payload: data.data});
          } else {
            dispatch(
              fetchError(messages['message.somthingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };


  export const onCreateContent = (formData: FormData,contentClass: string, props?: any, addToast?: any) => {
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      var config = {
        headers: {'Content-Type': 'multipart/form-data' }
      };
      axios.post('api/contents',formData, config)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: CREATE_NEW_CONTENT, payload: data.data});
            if(contentClass === ContentClass.CURATEDCONTENT){
              props("/content-annotation/" + data.data.id);
            }
            if(contentClass === ContentClass.ANNOUNCEMENT){
              props("/announcement/edit/" + data.data.id);
            }
            addToast('Saved Successfully', { appearance: 'success'});
          } else {
            addToast('Something Went Wrong ', { appearance: 'error'})
          }
        })
        .catch(error => {
          addToast('Something Went Wrong ', { appearance: 'error'})
        });
    };
  };

  export const onCreateQuestion = (formData: FormData,forumName?: string, props?: any, addToast?: any) => {
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());

      axios.post('api/contents/questions',formData)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: CREATE_NEW_QUESTION, payload: data.data});
         //   props("/review-question/" + data.data.id);
            props("/forums/" + forumName + "/review-question/" + data.data.id);

            addToast('Saved Successfully', { appearance: 'success'});
          } else {
            addToast('Something Went Wrong ', { appearance: 'error'})
          }
        })
        .catch(error => {
          addToast('Something Went Wrong ', { appearance: 'error'})
        });
    };
  };

  export const onCreateAnswer = (formData: FormData, parentId: number , addToast?: any) => {
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());

      axios.post('api/contents/answers/'+parentId,formData)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_ANSWER_LIST, payload: data.data});
            addToast('Saved Successfully', { appearance: 'success'});
          } else {
            addToast('Something Went Wrong ', { appearance: 'error'})
          }
        })
        .catch(error => {
          addToast('Something Went Wrong ', { appearance: 'error'})
        });
    };
  };

  export const onCreateAnswerComment = (formData: FormData , parentId: number, addToast?: any ) => {
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());

      axios.post('api/contents/answers/'+parentId,formData)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_ANSWER_LIST, payload: data.data});
            addToast('Saved Successfully', { appearance: 'success'});
          } else {
            addToast('Something Went Wrong ', { appearance: 'error'})
          }
        })
        .catch(error => {
          addToast('Something Went Wrong ', { appearance: 'error'})
        });
    };
  };

  export const onGetChildren = (
    pageSize: number,
    currentPage: number,
    domain: string,
    contentId?: string,
  ) => {
    const {messages} = appIntl();
    const page = currentPage ? currentPage : 0;
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.get('api/contents/chidrenContents/', {
        params: {
          pageSize: pageSize,
          page: page,
          domain: domain,
          contentId: contentId,
        },
      })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_ANSWER_LIST, payload: data.data});
          } else {
            dispatch(
              fetchError(messages['message.somthingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };


  export const onSavePreviewImage = (formData: FormData, id?: string, addToast?: any) => {
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.post('api/content/preview/image', formData,
      {
        params: {
          id: id
        }
      })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: CREATE_NEW_CONTENT, payload: data.data});
            addToast('Image Saved Successfully', { appearance: 'success'});
          } else {
            addToast('Something Went Wrong ', { appearance: 'error'})
          }
        })
        .catch(error => {
          addToast('Something Went Wrong ', { appearance: 'error'})
        });
    };
  };

  export const onParseFile = (
    formData: FormData,
    contentClass: string,
    pageSize: number,
    currentPage: number,
    domain: string,
    fileName: string,
    addToast?: any,
    ) => {
    const page = currentPage ? currentPage : 0; 
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.post('api/content/upload', formData,
        {
          params: {
            contentClass: contentClass,
            pageSize: pageSize,
            page: page,
            domain: domain
          },
          onUploadProgress: progressEvent => {
          }
        }
      )
      .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_CONTENT_LIST, payload: data.data});
            dispatch({type: PROCESSING_FILE_UPLOAD, payload: fileName});
            dispatch({type: CLOSING_FILE_UPLOAD, payload: false});
            addToast('Files Saved Successfully', { appearance: 'success'});
          }else{
            dispatch({type: FAILED_FILE_UPLOAD, payload: ''});
            addToast('Something Went Wrong ', { appearance: fileName})
          } 
      })
        .catch(error => {
          dispatch({type: FAILED_FILE_UPLOAD, payload: fileName});
          addToast('Something Went Wrong ', { appearance: 'error'})
        });
    };
  };

  export const onEditContent = (Content: ContentObj,addToast?: any) => {
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.post('api/contents', Content)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: CREATE_NEW_CONTENT, payload: data.data});
            addToast('Saved Successfully', { appearance: 'success'});
          } else {
            addToast('Something Went Wrong ', { appearance: 'error'})
          }
        })
        .catch(error => {
          addToast('Something Went Wrong ', { appearance: 'error'})
        });
    };
  };
  
  export const onDeleteContent = (id: number, contentClass: string, pageSize: number,
                                  currentPage: number,domain: string) => {
    const {messages} = appIntl();
    const page = currentPage ? currentPage : 0;
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.post('api/content/' + id + '/'+ contentClass +'/' + pageSize + '/' + page + '/' +  domain)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_CONTENT_LIST, payload: data.data});
            dispatch(showMessage(messages['message.AnnotationCreated'] as string));
          } else {
            dispatch(
              fetchError(messages['message.somethingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  }; 

    export const onDeleteContentExplorer = (
      contentId: number,
      pageSize: number,
      currentPage: number,
      filterText: string,
      domain: string,
      sphereTagIds: Array<number>,
      forceTagIds: Array<number>,
      tagTagIds: Array<number>,
      fromDate: any,
      toDate: any,
      useDispatch: any
    ) => {
    const {messages} = appIntl();
    const page = currentPage ? currentPage : 0;
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.get('api/content-explorer/delete' ,
      {
        params: {
          contentId: contentId,
          pageSize: pageSize,
          page: page,
          filterText: filterText,
          domain: domain,
          sphereTagId: sphereTagIds,
          forceTagId: forceTagIds,
          tagTagId: tagTagIds,
          fromDate:fromDate,
          toDate:toDate
        },
      })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_CONTENT_FILTER_LIST, payload: data.data});
            let contentIds: Array<number> = []
            data.data.content.map((c: any) => {
              contentIds = [...contentIds, c.id]
            })
            useDispatch(onGetTagCount(contentIds, domain));
            useDispatch(onGetAnnotationsByContentIds(contentIds));
            dispatch(showMessage(messages['message.AnnotationCreated'] as string));
          } else {
            dispatch(
              fetchError(messages['message.somethingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  }; 

  export const onDeleteImageContent = (contentName: string) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.post('api/content/delete/s3/image/' + contentName)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
          } else {
            dispatch(
              fetchError(messages['message.somethingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  }; 
  