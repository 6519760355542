import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";
import ReviewQuestion from "./forums/review-question";
const Dashboard = React.lazy(() => import('./dashboard-toffler'));
const Content = React.lazy(() => import("./contents"));
const ContentCreate = React.lazy(() => import('./contents/createContent'));
const ContentEdit = React.lazy(() => import('./contents/editContent'));
const ContentExplorer = React.lazy(() => import('./content-explorer'));
const Announcements = React.lazy(() => import('./announcements/announcementListing'));
const CreateAnnouncement = React.lazy(() => import('./announcements/createAnnouncement'));
const EditAnnouncement = React.lazy(() => import('./announcements/editAnnouncement'));
const FeaturedContents = React.lazy(() => import('./featured-contents'));
const Users = React.lazy(() => import('./users/userListing'));
const CreateUser = React.lazy(() => import('./users/createUser'));
const EditUser = React.lazy(() => import('./users/editUser'));
const CreateQuestion = React.lazy(() => import('./forums/create-question'));
const QuestionListing = React.lazy(() => import('./forums/questionListing'));


export const appConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/content-manager",
    element: <Content />,
    permittedRole: RoutePermittedRole.User,
  },
  {
    path: "/content/new",
    element: <ContentCreate />,
    permittedRole: RoutePermittedRole.User,
  },
  {
    path: ['/content-annotation/:id'],
    element: <ContentEdit />,
    permittedRole: RoutePermittedRole.User,
  },
  {
    path: "/content-explorer",
    element: <ContentExplorer />,
    permittedRole: RoutePermittedRole.User
  },
  {
    path: "/announcements",
    element: <Announcements />,
    permittedRole: RoutePermittedRole.Admin,
  },
  {
    path: "/announcement/new",
    element: <CreateAnnouncement />,
    permittedRole: RoutePermittedRole.Admin,
  },
  {
    path: "/announcement/edit/:id",
    element: <EditAnnouncement />,
    permittedRole: RoutePermittedRole.Admin,
  },
  {
    path: "/featured-contents",
    element: <FeaturedContents />,
    permittedRole: RoutePermittedRole.Admin,
  },
  {
    path: "/users",
    element: <Users />,
    permittedRole: RoutePermittedRole.Admin,
  },
  {
    path: "/user/new",
    element: <CreateUser />,
    permittedRole: RoutePermittedRole.Admin,
  },
  {
    path: "/user/edit/:id",
    element: <EditUser />,
    permittedRole: RoutePermittedRole.Admin,
  },
  {
    path: "/forums/:forumName/create-question",
    element: <CreateQuestion />,
    permittedRole: RoutePermittedRole.User,
  },
  {
    path: "/forums/:forumName",
    element: <QuestionListing />,
    permittedRole: RoutePermittedRole.User,
  },
  {
    path: "/forums/:forumName/review-question/:id",
    element: <ReviewQuestion />,
    permittedRole: RoutePermittedRole.User,
  },
];
