import axios from '../../shared/api-config';
import { appIntl } from "../../@crema/utility/helper/Utils";
import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {Dispatch} from 'redux';
import {AppActions} from '../../types';

import {
  CREATE_NEW_ANNOTATIONTAG,
    DELETE_ANNOTATIONTAG,
    GET_ANNOTATIONTAG_ITEM,
    GET_ANNOTATIONTAG_LIST
  } from '../../types/actions/AnnotationTag.action';
import { AnnotationTagObj } from 'types/models/apps/AnnotationTag';
import { GET_ANNOTATION_TAG } from 'types/actions/Tag.action';


export const onGetAnnotationTagList = (
  domain: string
) => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/tags', {
      params: {
        domain: domain
      },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({type: GET_ANNOTATIONTAG_LIST, payload: data.data});
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};


export const onGetAnnotationTagItem = (id: number) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.get('api/tag/'+id, {
      })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_ANNOTATIONTAG_ITEM, payload: data.data});
          } else {
            dispatch(
              fetchError(messages['message.somthingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };

  export const onCreateAnnotationForceTag = (AnnotationTag: AnnotationTagObj) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.post('api/annotationtag/force', AnnotationTag)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: CREATE_NEW_ANNOTATIONTAG, payload: data.data});
            dispatch(showMessage(messages['message.AnnotationTagCreated'] as string));
          } else {
            dispatch(
              fetchError(messages['message.somethingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };

  export const onCreateAnnotationSphereTag = (AnnotationTag: AnnotationTagObj) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.post('api/annotationtag/sphere', AnnotationTag)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: CREATE_NEW_ANNOTATIONTAG, payload: data.data});
            dispatch(showMessage(messages['message.AnnotationTagCreated'] as string));
          } else {
            dispatch(
              fetchError(messages['message.somethingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };

  export const onDeleteAnnotationTag = (annotationId: number, tagId: number) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.get('api/annotationtag/'+ annotationId + '/delete/' + tagId, {
      })
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_ANNOTATION_TAG, payload: data.data});
          } else {
            dispatch(
              fetchError(messages['message.somthingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  }; 

  export const onCreateAnnotationTag = (AnnotationTag: AnnotationTagObj) => {
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.post('api/annotationtag/tag', AnnotationTag)
        .then(data => {
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_ANNOTATION_TAG, payload: data.data});
          } else {
            dispatch(
              fetchError(messages['message.somethingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };
  