import {
    TagActions,
    GET_TAG_LIST,
    CREATE_NEW_TAG,
    GET_TAG_ITEM,
    DELETE_TAG,
    GET_ANNOTATION_TAG,
    GET_TAG_COUNT
} from '../../types/actions/Tag.action';

import {TagObj} from '../../types/models/apps/Tag';

const initialState: {
 tagList: TagObj[];
 totalTags: number;
 selectedTag: TagObj[] | null;
 tagItem: TagObj;
 annotationTags: TagObj[];
} = {
 tagList: [],
 totalTags: 0,
 selectedTag: null,
 tagItem: {
    id: 0,
    name: '',
    parentTagId: 0,
    level: 0,
    label: '',
    domain: 'DEMOOO',
    status: 'DRAFT',
    createdBy: '',
    createdDate: new Date,
    lastModifiedBy: '',
    lastModifiedDate: new Date,
 },
 annotationTags: [],
};


const TagReducer = (state= initialState, action: TagActions) => {
    switch (action.type) {
        case GET_TAG_LIST:
            return{
                ...state,
                tagList: action.payload,
                totalTags: action.payload
        } 
        case GET_TAG_ITEM:
            return{
                ...state,
                tagItem: action.payload,
                loading: true
        } 
        case GET_ANNOTATION_TAG:
            return{
                ...state,
                annotationTags: action.payload,
                loading: true
        } 
        case CREATE_NEW_TAG:
            return {
            ...state,
            tagItem:action.payload,
        }
        case GET_TAG_COUNT:
            return{
                ...state,
                tagCount: action.payload,
        }        
        case DELETE_TAG:
            return {
            ...state,
            tagList: action.payload.content,
            totalTags: action.payload.totalElements
        };
        default:
            return state;
    }

} 

export default TagReducer;