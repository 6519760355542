import axios from '../../shared/api-config';


import { appIntl } from "@crema/utility/helper/Utils";
import { Dispatch } from "react";
import { AppActions } from "types";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { GET_FORUM_LIST } from 'types/actions/Forum.action';

export const onGetForumList = (
  ) => {
      console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
    const {messages} = appIntl();
    return (dispatch: Dispatch<AppActions>) => {
      dispatch(fetchStart());
      axios.get('api/forums')
        .then(data => {
            console.log("ssssssssssssssssssssssssssssssssssssss")
          if (data.status === 200) {
            dispatch(fetchSuccess());
            dispatch({type: GET_FORUM_LIST, payload: data.data});
          } else {
            dispatch(
              fetchError(messages['message.somthingWentWrong'] as string),
            );
          }
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  };