import {
    ForumActions,
    GET_FORUM_LIST,
    CREATE_NEW_FORUM,
    GET_FORUM_ITEM,
    DELETE_FORUM,
    GET_ANNOTATION_FORUM,
    GET_FORUM_COUNT
} from '../../types/actions/Forum.action';

import {ForumObj} from '../../types/models/apps/Forum';

const initialState: {
 forumList: ForumObj[];
 totalForums: number;
 selectedForum: ForumObj[] | null;
 annotationForums: ForumObj[];
} = {
 forumList: [],
 totalForums: 0,
 selectedForum: null,
 annotationForums: [],
};

const ForumReducer = (state= initialState, action: ForumActions) => {
    switch (action.type) {
        case GET_FORUM_LIST:
            return{
                ...state,
                forumList: action.payload,
                totalForums: action.payload
        } 
        case GET_FORUM_ITEM:
            return{
                ...state,
                forumItem: action.payload,
                loading: true
        } 
        case GET_ANNOTATION_FORUM:
            return{
                ...state,
                annotationForums: action.payload,
                loading: true
        } 
        case CREATE_NEW_FORUM:
            return {
            ...state,
            forumItem:action.payload,
        }
        case GET_FORUM_COUNT:
            return{
                ...state,
                forumCount: action.payload,
        }        
        case DELETE_FORUM:
            return {
            ...state,
            forumList: action.payload.content,
            totalForums: action.payload.totalElements
        };
        default:
            return state;
    }

} 

export default ForumReducer;