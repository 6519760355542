import {ContentObj} from '../models/apps/Content';

export const CREATE_NEW_CONTENT = 'CREATE_NEW_CONTENT';
export const CREATE_NEW_QUESTION = 'CREATE_NEW_QUESTION';
export const DELETE_CONTENT = 'DELETE_CONTENT';
export const GET_CONTENT_LIST = 'GET_CONTENT_LIST';
export const GET_CONTENT_FILTER_LIST = 'GET_CONTENT_FILTER_LIST';
export const GET_ANNOUNCEMENT_LIST = 'GET_ANNOUNCEMENT_LIST';
export const GET_FEATURED_CONTENT_LIST = 'GET_FEATURED_CONTENT_LIST';
export const GET_CONTENT_ITEM = 'GET_CONTENT_ITEM';
export const UPDATE_CONTENT_DETAIL = 'UPDATE_CONTENT_DETAIL';
export const TOGGLE_CONTENT_DRAWER = 'TOGGLE_CONTENT_DRAWER';
export const PROCESSING_FILE_UPLOAD = 'PROCESSING_FILE_UPLOAD'
export const PROGRES_FILE_UPLOAD = 'PROGRES_FILE_UPLOAD'
export const FAILED_FILE_UPLOAD = 'FAILED_FILE_UPLOAD'
export const CLOSING_FILE_UPLOAD = 'CLOSING_FILE_UPLOAD'
export const CONTENT_TAB_VALUE = 'CONTENT_TAB_VALUE '
export const GET_ANSWER_LIST = 'GET_ANSWER_LIST'

export interface CreateContentActions {
    type: typeof CREATE_NEW_CONTENT;
    payload: ContentObj;
}

export interface CreateQuestionActions {
  type: typeof CREATE_NEW_QUESTION;
  payload: ContentObj;
}

export interface GetAnswersActions {
  type: typeof GET_ANSWER_LIST;
  payload: {content: ContentObj[]; totalElements: number};
}

export interface DeleteContentActions {
    type: typeof DELETE_CONTENT;
    payload: {content: ContentObj[]; totalElements: number};
}

export interface GetContentsActions {
  type: typeof GET_CONTENT_LIST;
  payload: {content: ContentObj[]; totalElements: number; totalPages: number};
}

export interface GetContentsFilterActions {
  type: typeof GET_CONTENT_FILTER_LIST;
  payload: {content: ContentObj[]; totalElements: number};
}

export interface GetAnnouncementsActions {
  type: typeof GET_ANNOUNCEMENT_LIST;
  payload: {content: ContentObj[]; totalElements: number};
}

export interface GetFeaturedContentActions {
  type: typeof GET_FEATURED_CONTENT_LIST;
  payload: {content: ContentObj[]; totalElements: number};
}

export interface GetContentItemActions {
  type: typeof GET_CONTENT_ITEM;
  payload: {item: ContentObj};
}

export interface UpdateContentActions {
  type: typeof UPDATE_CONTENT_DETAIL;
  payload: ContentObj;
}

export interface ToggleContentDrawerActions {
  type: typeof TOGGLE_CONTENT_DRAWER;
}

export interface ProcessingFileUpload {
  type: typeof PROCESSING_FILE_UPLOAD;
  payload: string;
}

export interface FailedFileUpload {
  type: typeof FAILED_FILE_UPLOAD;
  payload: string;
}

export interface ProgressFileUpload {
  type: typeof PROGRES_FILE_UPLOAD;
  payload: string;
}

export interface CloseUpload {
  type: typeof CLOSING_FILE_UPLOAD;
  payload: boolean;
}

export interface GetContentTabValue {
  type: typeof CONTENT_TAB_VALUE;
  payload: number;
}

export type ContentActions =
  | GetContentsActions
  | GetContentsFilterActions
  | UpdateContentActions
  | CreateContentActions
  | ToggleContentDrawerActions
  | DeleteContentActions
  | GetContentItemActions
  | ProcessingFileUpload
  | ProgressFileUpload
  | FailedFileUpload
  | GetAnnouncementsActions
  | GetFeaturedContentActions
  | CloseUpload
  | GetContentTabValue
  | CreateQuestionActions
  | GetAnswersActions;