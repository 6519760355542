import axios from '../../shared/api-config';
import { appIntl } from "../../@crema/utility/helper/Utils";
import { fetchError, fetchStart, fetchSuccess, showMessage } from './Common';
import { Dispatch } from 'redux';
import { AppActions } from '../../types';

import {
  CREATE_NEW_ANNOTATION,
  DELETE_ANNOTATION,
  GET_ANNOTATION_EDITOR_ANNOTAION_ID,
  GET_ANNOTATION_ITEM,
  GET_ANNOTATION_LIST,
  GET_ANNOTATION_TAG,
  GET_CONTENT_ANNOTATIONS,
  GET_OBSERVATIONS_TOFFLER_SPHERE
} from '../../types/actions/Annotation.action';
import { AnnotationObj } from 'types/models/apps/Annotation';


export const onGetAnnotationList = (
  type: string,
  name: string,
  currentPage: number,
) => {
  const { messages } = appIntl();
  const page = currentPage ? currentPage : 0;
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/Annotation', {
      params: {
        type: type,
        name: name,
        page: page,
      },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ANNOTATION_LIST, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onCreateAnnotation = (Annotation: AnnotationObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.post('api/annotation', Annotation)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: CREATE_NEW_ANNOTATION, payload: data.data });
          dispatch(showMessage(messages['message.AnnotationCreated'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};


export const onGetAnnotationItem = (
) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/Annotation/200', {
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ANNOTATION_ITEM, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetAnnotationTags = (id: number
) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/annotation/tag/' + id, {
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ANNOTATION_TAG, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetAnnotationByEditorAnnotationId = (id: string, annotationObj: AnnotationObj,
  usedispatch: any) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/annotation/comment/' + id, {
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          if (data.data.annotation == null) {
            usedispatch(onCreateAnnotation(annotationObj as AnnotationObj));
          } else {
            dispatch({ type: GET_ANNOTATION_EDITOR_ANNOTAION_ID, payload: data.data });
          }
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onDeleteAnnotation = (id: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.post('api/annotation/' + id)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: DELETE_ANNOTATION, payload: data.data });
          dispatch(showMessage(messages['message.AnnotationCreated'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetObservationsByTofflerSphere = (tagName: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/annotation/observations/' , {
      params: {
        tagName: tagName,
      },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_OBSERVATIONS_TOFFLER_SPHERE, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetObservationsByTofflerSphereTest = (tagName: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/annotation/observations/test' , {
      params: {
        tagName: tagName,
      },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_OBSERVATIONS_TOFFLER_SPHERE, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetAnnotationsByContentId = (id?: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/annotation/content/' + id, {
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CONTENT_ANNOTATIONS, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
}; 


export const onGetAnnotationsByContentIds = (ids: number[]) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    axios.get('api/annotation/contents/' , {
      params: {
        ids: ids,
      },
    })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_CONTENT_ANNOTATIONS, payload: data.data });
        } else {
          dispatch(
            fetchError(messages['message.somthingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
}; 