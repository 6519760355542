import React, { ReactNode } from "react";
import { BiAlignLeft } from "react-icons/bi";
import { RoutePermittedRole } from "../shared/constants/AppConst";

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}

const routesConfig: RouterConfigData[] =  [
  {
    id: 'dashboard',
    title: 'dashboard',
    messageId: 'sidebar.dashboard',
    type: 'item',
    icon: 'dashboard',
    url: '/dashboard',
  },
  /*
  {
    id: 'contentmanager',
    title: 'contentmanager',
    messageId: 'sidebar.content.manager',
    type: 'item',
    icon: 'view_list',
    url: '/content-manager',
  },
  */
  {
    id: 'contentexplorer',
    title: 'contentexplorer',
    messageId: 'sidebar.content.explorer',
    type: 'item',
    icon: 'report',
    url: '/content-explorer',
  },
  {
    id: 'admin-settings',
    title: 'admin-settings',
    messageId: 'sidebar.adminsettings',
    type: 'collapse',
    icon: 'dashboard',
    children: [
      {
        id: 'announcements',
        title: 'announcements',
        messageId: 'sidebar.adminsettings.announcements',
        type: 'item',
        url: '/announcements',
      },
      {
        id: 'featuredContents',
        title: 'featuredContents',
        messageId: 'sidebar.adminsettings.featuredContents',
        type: 'item',
        url: '/featured-contents',
      },
      {
        id: 'users',
        title: 'users',
        messageId: 'sidebar.adminsettings.users',
        type: 'item',
        url: 'users',
      }
    ],
  },
];
export default routesConfig;
