import {
    ProjectActions,
    GET_PROJECT_LIST,
    CREATE_NEW_PROJECT,
    GET_PROJECT_ITEM,
    DELETE_PROJECT
} from '../../types/actions/Project.action';

import {ProjectObj} from '../../types/models/apps/Project';


const initialState: {
 projectList: ProjectObj[];
 totalProjects: number;
 selectedProject: ProjectObj[] | null;
 projectItem: ProjectObj;
} = {
 projectList: [],
 totalProjects: 0,
 selectedProject: null,
 projectItem: {
    id: 0,
    name: '',
    domain: 'DEMOOO',
    status: 'DRAFT',
    createdBy: '',
    createdDate: new Date(),
    lastModifiedBy: '',
    lastModifiedDate: new Date(),
 },
};


const ProjectReducer = (state= initialState, action: ProjectActions) => {
    switch (action.type) {
        case GET_PROJECT_LIST:
            return{
                ...state,
                projectList: action.payload,
                totalProjects: action.payload.totalElements
        } 
        case GET_PROJECT_ITEM:
            return{
                ...state,
                projectItem: action.payload,
                loading: true
        } 
        case CREATE_NEW_PROJECT:
            return {
            ...state,
            projectItem:action.payload,
        };       
        case DELETE_PROJECT:
            return {
            ...state,
            projectList: action.payload,
            totalProjects: action.payload.totalElements
        };
        default:
            return state;
    }

} 

export default ProjectReducer;