import axios from "axios";

const configValue: string = (process.env.REACT_APP_BASE_URL as string);

const jwtAxios = axios.create({
  
  baseURL: configValue + "/api/user/signin",
  headers: {
    "Content-Type": "application/json",
  },
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === "Token is not valid") {
      console.log("Need to logout user");
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  }
);
jwtAxios.interceptors.request.use(
  (res) => {
    console.log("In cream/services/auth/jwt-auth/index.tsx");
    
    res.headers.common.Authorization = `Bearer ${localStorage.getItem("token")}`;
    
    return Promise.resolve(res);
  },
  (err) => {
    if (err.response && err.response.data.msg === "Token is not valid") {
      console.log("Need to logout user");
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  }
);
export const setAuthToken = (token?: string) => {
  console.log("set token --------> ");
  
  if (token) {
    jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem("token", token);
  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
    localStorage.removeItem("token");
  }
};

export default jwtAxios;
