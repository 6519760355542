import {TagObj} from '../models/apps/Tag';

export const CREATE_NEW_TAG = 'CREATE_NEW_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const GET_TAG_LIST = 'GET_TAG_LIST';
export const GET_TAG_ITEM = 'GET_TAG_ITEM';
export const GET_ANNOTATION_TAG = 'GET_ANNOTATION_TAG';
export const UPDATE_TAG_DETAIL = 'UPDATE_TAG_DETAIL';
export const TOGGLE_TAG_DRAWER = 'TOGGLE_TAG_DRAWER';
export const GET_TAG_COUNT = 'GET_TAG_COUNT'

export interface CreateTagActions {
    type: typeof CREATE_NEW_TAG;
    payload: TagObj;
}

export interface DeleteTagActions {
    type: typeof DELETE_TAG;
    payload: {content: TagObj[]; totalElements: number};
}

export interface GetTagsActions {
  type: typeof GET_TAG_LIST;
  payload: {content: TagObj[]; totalElements: number};
}

export interface GetTagItemActions {
  type: typeof GET_TAG_ITEM;
  payload: {item: TagObj};
}

export interface GetTagLEVELActions {
  type: typeof GET_ANNOTATION_TAG;
  payload: {item: any};
}

export interface UpdateTagActions {
  type: typeof UPDATE_TAG_DETAIL;
  payload: TagObj;
}

export interface ToggleTagDrawerActions {
  type: typeof TOGGLE_TAG_DRAWER;
}

export interface GetTagCount {
  type: typeof GET_TAG_COUNT;
  payload: any;
}

export type TagActions =
  | GetTagsActions
  | UpdateTagActions
  | CreateTagActions
  | ToggleTagDrawerActions
  | DeleteTagActions
  | GetTagItemActions
  | GetTagLEVELActions
  | GetTagCount;

