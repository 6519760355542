import AppList from "@crema/core/AppList";
import { useAuthUser } from "@crema/utility/AuthHooks";
import IntlMessages from "@crema/utility/IntlMessages";
import PersonIcon from '@mui/icons-material/Person';
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Editor } from "@tinymce/tinymce-react";
import moment from 'moment';
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { onCreateAnswerComment } from "redux/actions/Content";
import { AppState } from "redux/store";
import { ContentClass } from "types/constants/ContentClass";
import { ContentObj } from "types/models/apps/Content";
import CommentListItem from "./CommentListItem";


const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface ListItemProps {
  item: any;
}

const AnswerListItem: React.FC<ListItemProps> = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState<boolean>(false);

  const { user } = useAuthUser();

  const {
    childList,
    totalAnswers
  }: { childList: ContentObj[], totalAnswers: number } = useSelector<
    AppState,
    AppState['content']
  >(({ content }) => content);

  const tinyMCE_key = (process.env.REACT_APP_TINYMCE_KEY as string);
  const editorRef = useRef(null);
  const [contentHtml, setContentHtml] = useState<string>('');

  const changeContentHtml = (event) => {
    setContentHtml(event);
  }

  const [showComment, onShowComment] = useState<string>('none');

  const newComment: any = {
    id: 0,
    name: item.name,
    contentHtml: contentHtml,
    lastModifiedDate: new Date(),
    domain: user ? user.domain : '',
    contentClass: ContentClass.COMMENT,
    parentcontent: item
  };


  const saveAnswerComment = () => {
    console.log(newComment)
    dispatch(onCreateAnswerComment(newComment, item.parentcontent.id ,addToast));
    onShowComment('none');
    setContentHtml('');
  }


  return (
    <Card
      sx={{
        p: 5,
        mb: 4,
        cursor: "pointer",
      }}
      className="item-hover"
      onClick={() => {
        // dispatch(setCurrentProduct(item));
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "calc(100% - 8rem)",
              xl: "calc(100% - 10rem)",
            },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
              pb: 6,
              mt: -4,
            }}
          >
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
              mt: -4,
              width: '900',
            }}
          >
            <Box
              component="h3"
              sx={{
                color: "text.secondary",
                mb: 5,
                fontSize: 14,
                width: 1200
              }}
            >
              <Typography variant="h1" dangerouslySetInnerHTML={{ __html: item.contentHtml }} ></Typography>
            </Box>
          </Box>
          <Box
            sx={{
              mt: "auto",
              mb: -3,
              mx: { xs: -2, xl: -3 },
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                px: { xs: 2, xl: 3 },
                mb: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mr: 1,
                }}
              >
              </Box>
              <Box
                component="span"
                sx={{
                  fontSize: 14,
                }}
              >

                <Typography>
                  <PersonIcon />  answered {moment(item.createdDate).format("MMMM Do YYYY, H:MM ")} by {item.createdBy}
                </Typography>
                <Typography>
                  <AppList
                    delay={200}
                    data={childList?.filter(comment => (comment.contentClass === ContentClass.COMMENT && comment.parentcontent?.id === item.id))}
                    renderRow={(comment) => <CommentListItem item={comment} key={comment.id} />}
                  />
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => onShowComment('inline')}
                  >
                    <IntlMessages id='forum.comment' />
                  </Button>
                </Typography>
                <Box display={showComment}>
                  <Editor
                    apiKey={tinyMCE_key}
                    onInit={(evt: any, editor: any) => editorRef.current = editor}
                    value={contentHtml}
                    // initialValue={newQuestion.contentHtml}
                    onEditorChange={(newContentValue, editor) => { changeContentHtml(newContentValue) }}
                    init={{
                      height: 150,
                      menubar: false,
                      toolbar_mode: 'scrolling',
                      toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    }}
                  />
                  <Button
                    color='primary'
                    size="large"
                    variant="contained"
                    onClick={saveAnswerComment}
                  >
                    <IntlMessages id='forum.addComment' />
                  </Button>
                  <Button
                    color='secondary'
                    size="large"
                    variant="contained"
                    onClick={() => onShowComment('none')}
                  >
                    <IntlMessages id='common.cancel' />
                  </Button>

                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default AnswerListItem;
